@import url('https://fonts.googleapis.com/css2?family=Sorts+Mill+Goudy&display=swap');/*font-family: 'Sorts Mill Goudy', serif;*/
@import url('https://fonts.googleapis.com/css2?family=Courgette&display=swap');/*font-family: 'Courgette', cursive;*/
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;1,100&display=swap');//Sidebars

@font-face {/*font-family: 'Higuen';*/
    font-family: "Higuen";
    src: url("higuen-serif.otf");
}

$sidebars-bkg-color: #f7f5f5;
$sidebars-lnk-color: #070707;
$sidebars-lnk-hover-color: #8d8c8c;
$sidebars-hr-color: lightgray;

$contact-border-color: #7cdacd;
$contact-a-color: #0ea5eb;
$contact-a-hover-color: #136a8f;
body {
	margin: 0;
	padding: 0;
	a {
		font-size: larger;
		font-weight: bold;
		text-decoration: none;
		color: $contact-a-color;
		display: block;
		transition: 0.3s;
		padding-inline: 9px;
		&:hover {
			color: $contact-a-hover-color;
		}
	}
	h1 {
		font-family: 'Higuen';
		font-size: xx-large;
	}
	p {
		font-family: 'Sorts Mill Goudy', serif;
		font-size: large;
	}
	h2 {
		font-family: 'Higuen';
		font-size: large;
	}
}

.App {
    text-align: center;
  }  
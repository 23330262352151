@keyframes fade-in {
	from {
	  opacity: 0;
	}
	to {
	  opacity: 100;
	}
}

.srv {
	width: 90%;
	margin: auto;
	animation: fade-in 3s;
	h2 {		
		text-align: center;
	}	
	p {		
		text-align: right;
	}
}

.home {
	width: 90%;
	margin: auto;
	animation: fade-in 3s;
	p {
		text-align: justify;
	}
	h2 {
		text-align: center;
	}
}

.home2 {
	hr {
		margin-top: 63px;
		width: 0;
	}
}

.about {
	width: 100%;
	margin: auto;
	background-image: url('../img/AboutBkgS.jpg');
	background-repeat: repeat-y;
	background-size: contain;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 9px;
	//background-attachment: fixed;
	animation: fade-in 3s;
	div {
		width: 90%;
		margin: 0 auto;
		//background-color: white;
		margin-bottom: 23px;
		p {
			text-align: justify;
			margin: 0 18px;
		}
	}
}

.navbar {	
	display: flex;
	position: fixed;
	justify-content: space-between;	
	margin-bottom: 9px;
	width: 100%;
	max-width: 540px;
	background-color: white;
	z-index: 90;
	.navbtn-r, .navbtn-l {
		cursor:pointer;
		font-size:30px;
		&.navbtn-r {
			float: right;
			margin-top: 7px;
			margin-right: 9px;
		}	
		&.navbtn-l {
			float: left;						
			margin-left: 9px;
		}
	}	
} 

.globlayout{
	display: inline-block;
	align-items: center;
}

.content {
	text-align: center;
	justify-content: center;
	display: flexbox;
	flex-direction: initial;
	width: 100%;
	max-width: 540px;
	margin-top: 45px;
}

.testm {
	//width: 100%;
	background-color: rgb(245, 237, 220);
	animation: fade-in 3s;
	padding-bottom: 3px;
	article {
		width: 72%;
		margin: auto;
		border-radius: 9px;
		background-color: white;
		span {
			font-size: larger;
			color: orange;
		}
		p {		
			text-align: justify;
			font-size: medium;
			margin: 0 9px 7px;
		}
	}
}

.contact {
	width: 90%;
	margin: auto;
	animation: fade-in 3s;
	p {
		text-align: justify;
		margin: 9px;
	}
}

.smc {
	width: 90%;
	margin: auto;
	animation: fade-in 3s;
	p {
		text-align: justify;
	}
	button {
		padding: 9px 9px;
		background-color: gray;
		color: white;
		border: none;
		border-radius: 5px;
		cursor: pointer;
		&:hover {
			background-color: #888888;
		}
		&:active {
			background-color: #636262;
		}
	}
}

.fade_content {
	animation: fade-in 3s;
}
.sidenav, .rightnav {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    text-align: left;
    height: auto;
    width: 0;
    position: fixed;
    z-index: 10;
    top: 0;
    background-color: $sidebars-bkg-color;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    border-radius: 9px;
    opacity: 0.9;
    z-index: 108;
    &.sidebar {
        left: 0;    }
    &.rightnav {
        right: 0; 
    }
    a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 23px;
        color: $sidebars-lnk-color;
        display: block;
        transition: 0.3s;
        &:hover {
            color: $sidebars-lnk-hover-color;
        }
    }
    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 27px;
        margin-left: 50px; 
    }
    hr {
        height: 1px;
        border: none;
        background-color: $sidebars-hr-color;
        width: 81%;
    }
    ul {
        list-style: none;
        padding-left: 0;
        ul{
            padding-left: 1rem;
        }
    }
}
  
@media screen and (max-height: 450px) {
    .sidenav, .rightnav {padding-top: 15px;}
    .sidenav a, .rightnav a {font-size: 18px;}
}